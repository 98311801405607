<template>
  <div class="footer">
    <safe-area>
      <div class="footer-mark">
        <div class="footer-mark-item">
          <svg-standard color="#333333" height="2rem" width="2rem"></svg-standard>
          <span>{{ lang === 'en' ? 'Standard' : '标品现货' }}</span>
        </div>
        <div class="footer-mark-item">
          <svg-design color="#333333" height="2rem" width="2rem"></svg-design>
          <span>{{ lang === 'en' ? 'Custom Support' : '非标定制' }}</span>
        </div>
        <div class="footer-mark-item">
          <svg-develop color="#333333" height="2rem" width="2rem"></svg-develop>
          <span>{{ lang === 'en' ? 'R&D' : '产品研发' }}</span>
        </div>
        <div class="footer-mark-item">
          <svg-quality color="#333333" height="2rem" width="2rem"></svg-quality>
          <span>{{ lang === 'en' ? 'Quality' : '质量保证' }}</span>
        </div>
        <div class="footer-mark-item">
          <svg-sf color="#333333" height="2rem" width="2rem"></svg-sf>
          <span>{{ lang === 'en' ? 'SF Express' : '顺丰速运' }}</span>
        </div>
      </div>
      <div class="footer-content">
        <div class="footer-link">
          <div class="footer-link-group">
            <div class="footer-link-group-title">{{ lang === 'en' ? 'About Us' : '关于我们' }}</div>
            <div class="footer-link-items">
              <a class="footer-link-item" href="/about/profile">{{ lang === 'en' ? 'Company Profile' : '公司介绍' }}</a>
              <a class="footer-link-item" href="/about/news">{{ lang === 'en' ? 'News' : '新闻动态' }}</a>
              <a class="footer-link-item" href="/about/information">{{ lang === 'en' ? 'Exhibition' : '展会信息' }}</a>
            </div>
          </div>
          <div class="footer-link-group">
            <div class="footer-link-group-title">{{ lang === 'en' ? 'Technical' : '技术中心' }}</div>
            <div class="footer-link-items">
              <a class="footer-link-item" href="/tech/post">{{ lang === 'en' ? 'Technical Article' : '技术文章' }}</a>
              <a class="footer-link-item" href="/tech/tool">{{ lang === 'en' ? 'Calculator' : '计算工具' }}</a>
              <!--              <a class="footer-link-item" href="/tech/video">视频资源</a>-->
            </div>
          </div>
          <div class="footer-link-group">
            <div class="footer-link-group-title">{{ lang === 'en' ? 'Support' : '支持中心' }}</div>
            <div class="footer-link-items">
              <!--              <a class="footer-link-item" href="/custom/index">定制服务</a>-->
              <a class="footer-link-item" href="/help/index">{{ lang === 'en' ? 'Help' : '帮助中心' }}</a>
              <!--              <a class="footer-link-item" href="/custom/handbook">获取手册</a>-->
            </div>
          </div>
          <div class="footer-link-group">
            <div class="footer-link-group-title">{{ lang === 'en' ? 'Contact' : '联系我们' }}</div>
            <div class="footer-link-items">
              <a class="footer-link-item" href="/about/contact">{{ lang === 'en' ? 'Contact Us' : '联系我们' }}</a>
              <a class="footer-link-item"
                 href="https://work.weixin.qq.com/kfid/kfc3b01ed6d6527572d">{{ lang === 'en' ? 'Chat Online' : '在线咨询' }}</a>
            </div>
          </div>
        </div>
        <div class="footer-contact">
          <img class="contact-ercode" src="../assets/footer/dsjt-wechat.jpg"/>
          <img class="contact-ercode" src="../assets/footer/fjds-wechat.jpeg"/>
          <!--          <img class="contact-ercode" src="../assets/footer/fjds-wechat.jpeg"/>-->
          <a href="tel:86-591-83215681">
            <div class="contact-phone">86-591-83215681</div>
          </a>
          <div class="contact-time">9:00-18:00（周一至周五）</div>
          <div class="contact-fax">传真: 86-591-83215359</div>
          <div class="contact-address">福建省福州市高新开发区南屿高岐工业区安厦66号6栋</div>
          <div class="contact-address">Building 6, No. 66 Anxia Road, Nanyu Gaoqi Industrial Park, Fuzhou High-tech
            Zone, Fuzhou, Fujian 350108, China
          </div>
          <div class="contact-email">sales@dayoptics.com</div>
          <div class="contact-email">USsales@dayoptics.com</div>
        </div>
      </div>
    </safe-area>
  </div>
</template>

<script>
import SvgSf from "@/svg/svg-sf";
import SvgQuality from "@/svg/svg-quality";
import SvgDevelop from "@/svg/svg-develop";
import SvgDesign from "@/svg/svg-design";
import SvgStandard from "@/svg/svg-standard";
import SafeArea from "@/layout/safe-area";
import {getLang} from "@/utils/lang";

export default {
  name: "footer-view",
  components: {SafeArea, SvgStandard, SvgDesign, SvgDevelop, SvgQuality, SvgSf},
  data() {
    return {
      lang: getLang(),
    }
  },
}
</script>

<style scoped>
@media screen and (min-width: 756px) {
  .footer {
    background-color: #ffffff;
    padding: 2rem 0;
  }

  .footer-mark {
    display: flex;
    padding: 2rem 0;
  }

  .footer-mark-item {
    display: flex;
    flex: 1;
    align-items: center;

  }

  .footer-mark-item span {
    padding-left: 0.5rem;
    color: #333333;
    font-size: 1rem;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
  }

  .footer-contact {
    padding-left: 3rem;
    border-left: 1px solid #eeeeee;
    margin-left: 3rem;
    flex: 0 0 30%;
  }

  .footer-link {
    display: flex;
    flex: 1 0 auto;

  }

  .footer-link-group {
    display: block;
    flex: 1;
  }

  .footer-link-group-title {
    font-size: 1.2rem;
    color: #333333;
  }

  .footer-link-item {
    display: block;
    margin-top: 1rem;
    font-size: 0.8rem;
    color: #666666;
    padding-left: 1px;
  }

  .contact-phone {
    font-size: 1.3rem;
    font-weight: bold;
    color: #ab1018;
  }

  .footer-contact {
    text-align: right;
    font-size: 0.8rem;
    color: #666666;
  }

  .contact-time {
    color: #888888;
    font-size: 0.6rem;
    padding: 0.2rem 0;
  }

  .contact-fax {
    padding: 0.5rem 0;
  }

  .contact-address {
    padding-bottom: 0.7rem;
  }

  .contact-ercode {
    width: 5rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 755px) {
  .footer {
    background-color: #ffffff;
    padding: 2rem 0;
  }

  .footer-mark {
    display: flex;
    padding: 1rem 0;
  }

  .footer-mark-item {
    display: flex;
    flex: 1;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

  }

  .footer-mark-item span {
    color: #666666;
    font-size: 0.7rem;
    flex: 0 0 100%;
    text-align: center;
    padding-top: 0.5rem;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    flex-wrap: wrap;
  }

  .footer-contact {
    border-top: 1px solid #eeeeee;
    width: 100%;
    flex: 0 0 100%;
    padding: 1rem 0;
    margin: 1rem 0;
  }

  .footer-link {
    display: flex;
    flex: 0 0 100%;
  }

  .footer-link-group {
    display: block;
    flex: 1;
    text-align: center;
  }

  .footer-link-group-title {
    font-size: 0.9rem;
    color: #666666;
    font-weight: bold;
  }

  .footer-link-item {
    display: block;
    margin-top: 1rem;
    font-size: 0.8rem;
    color: #666666;
  }

  .contact-phone {
    font-size: 1.3rem;
    font-weight: bold;
    color: #ab1018;
  }

  .footer-contact {
    text-align: center;
    font-size: 0.8rem;
    color: #666666;
  }

  .contact-time {
    color: #888888;
    font-size: 0.6rem;
    padding: 0.2rem 0;
  }

  .contact-fax {
    padding: 0.5rem 0;
  }

  .contact-address {
    padding-bottom: 0.7rem;
  }

  .contact-ercode {
    width: 5rem;
    margin: 1rem 0.6rem;
  }
}
</style>
