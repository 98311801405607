export function setLang(lang) {
    localStorage.setItem('lang', lang)
}

export function getLang() {
    const l = localStorage.getItem('lang')
    if(!l){
        return  navigator.language.substring(0, 2) === 'zh' ? 'zh-CN' : 'en'
    }
    return l
}
