<template>
  <div id="app" class="app">
    <basic/>
  </div>

</template>
<script>
import basic from "@/layout/basic";

export default {
  components: {
    basic
  }
}
</script>
<style>
@import 'css/common.css';
</style>
<style scoped>

</style>
<style>

</style>
