<template>
  <div class="copyright">
    <safe-area>
      <span>Copyright ©2005-{{ new Date().getFullYear() }} 戴斯光电 DayOptics  版权所有  <a
          href="https://beian.miit.gov.cn/" target="_blank">湘ICP备13006227号-2</a> </span>
    </safe-area>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";

export default {
  name: "copyright",
  components: {SafeArea}
}
</script>

<style scoped>
@media screen and (min-width: 756px) {
  .copyright {
    background-color: #252525;
    color: #efefef;
    padding: 1rem 0;
    text-align: center;
    font-size: 0.8rem;
  }

  .copyright a {
    color: #efefef;
    border-left: 1px solid #666666;
    padding-left: 1rem;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 755px) {
  .copyright {
    background-color: #252525;
    color: #efefef;
    padding: 1rem 0;
    text-align: center;
    font-size: 0.8rem;
  }

  .copyright a {
    color: #efefef;
    display: block;
    text-align: center;
  }
}

</style>
