<template>
  <div class="tool-bar">
    <safe-area class="tool-bar-area">
      <div class="tool-bar-left">
        <div v-if="lang" class="lang">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link" style="color: #ececec;">
              {{ lang === 'zh-CN' ? '简体中文' : 'English' }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="zh-CN" v-if="lang == 'en'">简体中文</el-dropdown-item>
              <el-dropdown-item command="en" v-if="lang == 'zh-CN'">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="shortcut">
          <a href="/about/contact">{{ lang ==='en'?'Contact':'联系我们' }}</a>
          <a href="/about/profile">{{lang === 'en'?'About Us':'公司介绍'}}</a>
          <a href="/about/information">{{lang === 'en'?'Exhibition':'展会信息'}}</a>
        </div>
      </div>
      <div class="tool-bar-right">
        <div class="shortcut">
          <a href="/user/login" v-if="token==0">{{lang === 'en'?'Login':'登录'}}</a>
          <a href="/user/order">{{lang === 'en'?'My Order':'我的订单'}}</a>
          <a href="/user/cart"><i class="el-icon-shopping-cart-2"></i> {{lang === 'en'?'Cart':'购物车'}}</a>
        </div>
      </div>
    </safe-area>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";
import {getLang, setLang} from "@/utils/lang";

export default {
  name: "tool-bar",
  components: {SafeArea},
  data() {
    return {
      lang: getLang(),
      token: this.getToken()
    }
  },
  methods:{
    getToken(){
      return localStorage.getItem('token') || 0
    },
    handleCommand(lang){
      console.log(lang)
      setLang(lang)
      window.location.reload()
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 756px) {
  .tool-bar {
    background-color: #333;
    color: #ececec !important;
    font-size: 0.7rem;
    padding: 0.6rem 0;
    font-weight: 350;
  }

  .tool-bar span {
    font-size: 0.7rem;
  }

  .tool-bar-area {
    display: flex;
    align-items: center;
    justify-content: space-between
  }

  .tool-bar-left {
    display: flex;
    align-items: center;
  }

  .shortcut {
    display: flex;
    padding-left: 1rem;
  }

  .shortcut a {
    padding: 0 1rem;
    color: #ececec !important;
  }
}

@media screen and (max-width: 755px) {
  .tool-bar {
    background-color: #333;
    color: #ececec !important;
    font-size: 0.8rem;
    padding: 0.8rem 0;
    font-weight: 350;
  }

  .tool-bar span {
    font-size: 0.8rem;
  }

  .tool-bar-area {
    display: flex;
    align-items: center;
    justify-content: space-between
  }

  .tool-bar-left > .shortcut {
    display: none;
  }

  .lang {
    margin-left: 1rem;
  }

  .shortcut a {
    padding: 0 1rem;
    color: #ececec !important;
  }
}
</style>
