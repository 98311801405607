<template>
  <div class="com-safe-area">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "safe-area"
}
</script>

<style scoped>

</style>
