<template>
  <svg :fill="color" :height="height" :width="width" p-id="4310" t="1645435815309" version="1.1"
       viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M512 195.2L741.6 272 512 348.8 282.4 272 512 195.2m0-67.2L128 256v32l384 128 384-128v-32L512 128z m0 389.6l-320-104h-64v32l384 128 384-128v-32h-64l-320 104zM512 680L192 576h-64v32l384 128 384-128v-32h-64L512 680z m0 160L192 736h-64v32l384 128 384-128v-32h-64l-320 104z"
        p-id="4311"></path>
  </svg>
</template>

<script>
export default {
  name: "svg-develop",
  props: {
    height: {
      type: String,
      default: "50px"
    },
    width: {
      type: String,
      default: "50px"
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
