<template>
  <div class="basic">
    <div class="header">
      <div class="header-tool-bar">
        <toolBar/>
      </div>
      <div class="header-menu">
        <header-menu></header-menu>
      </div>
    </div>
    <div class="page-area">
      <router-view></router-view>
    </div>
    <div class="footer">
      <footerCom/>
    </div>
    <div class="copyright">
      <copyright/>
    </div>
    <el-backtop></el-backtop>
  </div>

</template>

<script>
import toolBar from "@/layout/tool-bar";
import HeaderMenu from "@/layout/header-menu";
import footerCom from "@/layout/footer-view";
import Copyright from "@/layout/copyright";

export default {
  name: "basic",
  components: {
    Copyright,
    HeaderMenu,
    toolBar,
    footerCom
  }
}
</script>

<style scoped>
@media screen and (max-width: 755px) {
  .page-area {
    max-width: 100vw;
    overflow: auto;
  }
}

@media screen and (min-width: 756px) {

}
</style>
