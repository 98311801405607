<template>
  <svg :fill="color" :height="height" :width="width" p-id="4018" t="1645435444016" version="1.1"
       viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M512 134.4l289.6 133.6V472c0 96.8-31.2 193.6-88.8 272.8-52.8 73.6-124 126.4-200.8 150.4-77.6-24-148-76.8-200.8-150.4-57.6-79.2-88.8-176-88.8-272.8V268L512 134.4m0-71.2L158.4 226.4v244.8c0 226.4 151.2 437.6 353.6 489.6 203.2-51.2 353.6-263.2 353.6-489.6V226.4L512 63.2z m191.2 447.2c0-106.4-85.6-192-192-192s-192 85.6-192 192 85.6 192 192 192c24 0 46.4-4.8 68-12l58.4 58.4 45.6-45.6-47.2-47.2c40.8-35.2 67.2-87.2 67.2-145.6z m-192 128c-70.4 0-128-57.6-128-128s57.6-128 128-128 128 57.6 128 128c0 40.8-19.2 76.8-48.8 100l-43.2-43.2-45.6 45.6 24.8 24.8c-4.8 0-10.4 0.8-15.2 0.8z"
        p-id="4019"></path>
  </svg>
</template>

<script>
export default {
  name: "svg-quality",
  props: {
    height: {
      type: String,
      default: "50px"
    },
    width: {
      type: String,
      default: "50px"
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
