import Vue from 'vue'
import VueRouter from 'vue-router'
import {getToken} from "@/utils/token";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/index.vue')
    },
    {
        path: '*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "not-found" */ '../views/result-page/not-found.vue')
    },
    {

        path: '/user/login',
        name: 'UserLogin',
        meta: {
            title: '登录',
        },
        component: () => import(/* webpackChunkName: "user-login" */ '../views/user/login.vue')
    },
    {
        path: '/user/order',
        name: 'UserOrder',
        meta: {
            login: true,
            title: '我的订单',
        },
        component: () => import(/* webpackChunkName: "user-order" */ '../views/user/order.vue')
    },
    {
        path: '/user/cart',
        name: 'UserCart',
        meta: {
            login: true,
            title: '购物车',
        },
        component: () => import(/* webpackChunkName: "user-cart" */ '../views/user/cart.vue')
    }, {
        path: '/user/address',
        name: 'UserAddress',
        meta: {
            login: true,
            title: '我的地址',
        },
        component: () => import(/* webpackChunkName: "user-cart" */ '../views/user/addressPage.vue')
    },
    {
        path: '/about/contact',
        name: 'Contact',
        meta: {
            title: '联系我们',
        },
        component: () => import(/* webpackChunkName: "about-contact" */ '../views/about/contact.vue')
    },
    {
        path: '/about/profile',
        name: 'AboutProfile',
        meta: {
            title: '公司介绍',
        },
        component: () => import(/* webpackChunkName: "about-profile" */ '../views/about/profile.vue')
    },
    {
        path: '/help/index',
        name: 'HelpIndex',
        meta: {
            title: '帮助中心',
        },
        component: () => import(/* webpackChunkName: "help-index" */ '../views/help/index.vue')
    },
    {
        path: '/about/news',
        name: 'AboutNews',
        meta: {
            title: '公司动态',
        },
        component: () => import(/* webpackChunkName: "about-news" */ '../views/about/news.vue')
    },
    {
        path: '/about/information',
        name: 'AboutInformation',
        meta: {
            title: '展会信息',
        },
        component: () => import(/* webpackChunkName: "about-information" */ '../views/about/information.vue')
    },
    {
        path: '/tech/post',
        name: 'TechPost',
        meta: {
            title: '技术文章',
        },
        component: () => import(/* webpackChunkName: "tech-post" */ '../views/tech/post.vue')
    },
    {
        path: '/tech/tool',
        name: 'TechTool',
        meta: {
            title: '计算工具',
        },
        component: () => import(/* webpackChunkName: "tech-post" */ '../views/tech/tool.vue')
    },
    {
        path: '/product/index',
        name: 'ProductIndex',
        meta: {
            title: '产品中心',
        },
        component: () => import(/* webpackChunkName: "product-index" */ '../views/product/index.vue')
    },
    {
        path: '/product/searchView',
        name: 'ProductSearchView',
        meta: {
            title: '搜索结果',
        },
        component: () => import(/* webpackChunkName: "product-index" */ '../views/product/searchView.vue')
    },
    {
        path: '/product/list',
        name: 'ProductList',
        meta: {
            title: '产品',
        },
        component: () => import(/* webpackChunkName: "product-list" */ '../views/product/list.vue')
    },
    {
        path: '/content/detail',
        name: 'ContentDetail',
        meta: {
            title: '内容详情',
        },
        component: () => import(/* webpackChunkName: "product-list" */ '../views/content/detail.vue')
    },

]


const router = new VueRouter({
    routes,
    mode: "history"
})

//前置导航守卫，负责token验证和加载动态路由
router.beforeEach((to, from, next) => {
    console.log(to)
    if (to.meta && to.meta.login && !getToken()) {
        next({name: 'UserLogin'})//不在白名单并且没有本地token时，切换到login（可使用接口校验token接口进行强校验）
        return
    }
    next()
})

//后置导航守卫，负责处理title和其它事务
router.afterEach((to, from) => {
    const {title} = to.meta;
    if (title) {
        document.title = title + ' | ' + process.env.VUE_APP_TITLE
    } else {
        document.title = process.env.VUE_APP_TITLE
    }
})

export default router
