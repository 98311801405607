<template>
  <svg :fill="color" :height="height" :width="width" p-id="4018" t="1645435613498" version="1.1"
       viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M512.8 142.4L768 325.6V832H256V325.6l256.8-183.2m0-71.2c-6.4 0-12.8 1.6-18.4 5.6L104 355.2c-12.8 8.8-6.4 28.8 9.6 28.8H192v496c0 8.8 7.2 16 16 16h608c8.8 0 16-7.2 16-16V384h78.4c15.2 0 21.6-20 9.6-28.8L531.2 77.6c-5.6-4-12-6.4-18.4-6.4zM704 640H576v128h128V640z m-256 0H320v128h128V640z m128-192H448v128h128V448z"
        p-id="4019"></path>
  </svg>
</template>

<script>
export default {
  name: "svg-standard",
  props: {
    height: {
      type: String,
      default: "50px"
    },
    width: {
      type: String,
      default: "50px"
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
