<template>
  <svg :fill="color" :height="height" :width="width" p-id="4164" t="1645435666599" version="1.1"
       viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M772.8 124c-4.8 0-10.4 2.4-14.4 6.4L184 704.8l-56 192 192-56 460-460L800 360l94.4-94.4c7.2-7.2 8-18.4 2.4-24.8L783.2 128c-2.4-3.2-6.4-4-10.4-4z m4.8 213.6L687.2 247.2l85.6-85.6 90.4 90.4-85.6 85.6z m-602.4 512l37.6-128 454.4-454.4 90.4 90.4-454.4 454.4-128 37.6z m84-725.6l-136 136 234.4 234.4 22.4-22.4-211.2-212 90.4-90.4 53.6 53.6-22.4 22.4 22.4 22.4 22.4-22.4 45.6 45.6-22.4 22.4 22.4 22.4 22.4-22.4 45.6 45.6-45.6 45.6 22.4 22.4 68-68-234.4-235.2z m415.2 415.2l-22.4 22.4-22.4 22.4 22.4 22.4 22.4-22.4 45.6 45.6-45.6 45.6 22.4 22.4 45.6-45.6 45.6 45.6-22.4 22.4 22.4 22.4 22.4-22.4 53.6 53.6L772.8 864 560.8 652.8l-22.4 22.4 234.4 234.4 136-136-234.4-234.4z"
        p-id="4165"></path>
  </svg>
</template>

<script>
export default {
  name: "svg-design",
  props: {
    height: {
      type: String,
      default: "50px"
    },
    width: {
      type: String,
      default: "50px"
    },
    color: {
      type: String,
      default: '#000000'
    }
  }
}
</script>

<style scoped>

</style>
