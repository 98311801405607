<template>
  <div class="search">
    <el-input
        v-model="searchKey"
        :placeholder="lang === 'en'?'Search':'搜索关键词'"
        prefix-icon="el-icon-search"
        @change="search"
    >
    </el-input>
    <!--    <div class="search-btn">-->
    <!--      <i class="el-icon-search"></i>-->
    <!--    </div>-->
  </div>
</template>

<script>
import {getLang} from "@/utils/lang";

export default {
  name: "search",
  data() {
    return {
      lang: getLang(),
      searchKey: '',
      path: ''
    }
  },
  methods: {
    search() {
      this.$router.push({
        path: '/product/searchView?keyword=' + this.searchKey
      })
    }
  }
}
</script>

<style scoped>
.search {
  display: flex;
}

.search-btn {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F56C6C;
  color: #ffffff;
}

.el-input__inner {
  border-radius: 0 !important;
}
</style>
