<template>
  <div class="menu">
    <safe-area class="menu-area">
      <div class="logo-area">
        <a href="/"> <img class="logo" src="../assets/logo-org.png"/></a>
      </div>

      <el-menu :router="true" active-text-color="#C9151e" class="menu-items"
               mode="horizontal" text-color="#333333">
        <el-menu-item index="/">{{lang === 'en'?'Index':'首页'}}</el-menu-item>
        <el-menu-item index="/product/index">{{lang === 'en'?'Products':'产品'}}</el-menu-item>
        <!--        <el-submenu index="2">-->
        <!--          <template slot="title">定制&案例</template>-->
        <!--          <el-menu-item index="/custom/index">定制中心</el-menu-item>-->
        <!--          <el-menu-item index="/post/index?type=5">应用案例</el-menu-item>-->
        <!--          <el-menu-item index="/custom/handbook">获取手册</el-menu-item>-->
        <!--        </el-submenu>-->
        <el-submenu index="2-4">
          <template slot="title">{{lang === 'en'?'Technical':'技术中心'}}</template>
          <el-menu-item index="/tech/post">{{lang === 'en'?'Technical Article':'技术文章'}}</el-menu-item>
          <el-menu-item index="/tech/tool">{{lang === 'en'?'Calculator':'计算工具'}}</el-menu-item>
          <!--          <el-menu-item index="/tech/video">视频资源</el-menu-item>-->
        </el-submenu>
        <el-submenu index="2-5">
          <template slot="title">{{lang === 'en'?'About Us':'关于我们'}}</template>
          <el-menu-item index="/about/profile">{{lang === 'en'?'Company Profile':'公司介绍'}}</el-menu-item>
          <el-menu-item index="/about/news">{{lang === 'en'?'News':'新闻动态'}}</el-menu-item>
          <el-menu-item index="/about/information">{{lang === 'en'?'Exhibition':'展会信息'}}</el-menu-item>
          <el-menu-item index="/about/contact">{{lang === 'en'?'Contact':'联系我们'}}</el-menu-item>
        </el-submenu>
        <el-menu-item index="/help/index">{{lang === 'en'?'Help Center':'帮助中心'}}</el-menu-item>
      </el-menu>
      <div class="search">
        <search></search>
      </div>

    </safe-area>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";
import Search from "@/layout/search";
import {getLang} from "@/utils/lang";

export default {
  name: "header-menu",
  components: {Search, SafeArea},
  data() {
    return {
      lang: getLang(),
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 756px) {
  .menu-area {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 1rem 0;
    justify-content: space-between;
  }

  .logo-area {
    flex: 0 0 225px;
    width: 225px;
  }

  .logo {
    width: 150px;
    margin-right: 20px;
  }

  .menu-items {
    border-bottom: 0px solid #000000 !important;
    flex: 1 1 auto;
  }

  .menu {
    box-shadow: 0px 3px 10px -7px rgba(0, 0, 0, 0.5);
  }
}

@media screen and (max-width: 755px) {
  .menu-area {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.6rem 0;
    justify-content: space-between;
  }

  .logo-area {
    width: 80px;
    flex: 0 0 80px;
  }

  .logo {
    width: 80px;
    margin-left: 20px;
  }

  .menu-items {
    border-bottom: 0px solid #000000 !important;
    flex: 1 1 auto;
    display: none;
  }

  .menu {
    box-shadow: 0px 3px 10px -7px rgba(0, 0, 0, 0.5);
  }

  .search {
    display: none;
  }
}

.menu {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
}
</style>
